import axios from 'axios'
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Posts = ({ posts, loading,setRelod }) => {
    let myposts = posts
    const [refresh, setRefresh] = useState(1)
    const navigate = useNavigate();
    if (loading) {
        return <h2>loading....</h2>
    }
    const headers = {
        'Authorization': 'refresh'

    }
    const data = {
        foo: 'bar'
    }

    //delete function is working
    const deleteUsers = async (id) => {
        try {
            const res = await axios.delete(`/api/v1/deleteusers/${id}`)

                .then((res) => {
                    toast.error("Users has been Deleted", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(() => {
                    toast.error("sorry only admin can insert", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })

        } catch (error) {
            console.log(error)
        }
        setRelod(id)
    }

    const goToEditPage = (value) => {
        navigate('/editusers', { state: { value } })
        // navigate('/editusers')
    }
    return (
        <div className="col-lg-12">
            <table className="table">
                <thead>
                    <tr>

                        <th scope="col">Users Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Email</th>
                       
              

                      
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        posts && posts.map((item) => (
                            <tr key={item.id} className="p-5">
                                <td scope="row">{item.name}</td>
                                <td scope="row">{item.role}</td>
                                <td scope="row">{item.email}</td>
           


                                <td><button className="btn btn-primary" value={item.id}
                                    onClick={(e) => { goToEditPage(e.target.value) }}
                                >Edit</button></td>
                                {/* <td><button className="btn btn-primary" onClick={(e) => { goToEditPage() }}>Edit</button></td> */}
                                <td><button className="btn btn-danger" value={item.id}
                                    onClick={(e) => { deleteUsers(e.target.value) }}>Delete</button></td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Posts