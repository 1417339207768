import React from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from '../Siderbar/Sidebar'

const MainLayout = () => {
  return (
    <div style={{display:'flex',flexDirection:'row'}} className="dashboard-card-bg">
        <SideBar /> 
        <div className='container-fluid ps-0'>
            <Outlet />
        </div>
    </div>
  )
}

export default MainLayout