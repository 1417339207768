//product Reducer
import {
    GET_TAXES_BY_CATEGORY_REQUEST,
    GET_TAXES_BY_CATEGORY_SUCCESS,
    GET_TAXES_BY_CATEGORY_SUCCESS_FAIL,
    ALL_TAX_REQUEST,
    ALL_TAX_SUCCESS,
    ALL_TAX_FAIL,


    CLEAR_ERRORS
} from '../Constrants/TaxConstrants'
// export const productReducer = (state = { products : [] }, action) => {
//     switch (action.type) {
//         case ALL_PRODUCT_REQUEST:
//             return {
//                 loading: true,
//                 categories: []
//             }
//         case ALL_PRODUCT_SUCCESS:
//             return {
//                 loading: false,
//                 rows: action.payload.rows,

//             }
//         case ALL_PRODUCT_FAIL:
//             return {
//                 loading: false,
//                 error: action.payload
//             }
//         case CLEAR_ERRORS:
//             return {
//                 ...state,
//                 error: null
//             }
//         default:
//             return state
//     }
// }
export const taxReducer = (state = { taxes: [] }, action) => {
    switch (action.type) {
        case ALL_TAX_REQUEST:
            return {
                loading: true,
                categories: []
            }
        case ALL_TAX_SUCCESS:
            return {
                loading: false,
                rows: action.payload.rows,

            }
        case ALL_TAX_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        // case GET_PRODUCTS_BY_CATEGORY_REQUEST:
        //     return {
        //         loading: true,
        //         products: []
        //     }
        // case GET_PRODUCTS_BY_CATEGORY_SUCCESS:
        //     return {
        //         loading: false,
        //         rows: action.payload.rows,
        //     }
        // case GET_PRODUCTS_BY_CATEGORY_SUCCESS_FAIL:
        //     return {
        //         loading: false,
        //         error: action.payload
        //     }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}