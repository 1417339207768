import { NavLink } from "react-router-dom";
import { FaBars, FaHome, FaUser } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCartCheck, BsTag } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { userReducer } from "../../services/Reducers/userReducer";


const SideBar = ({ children }) => {
  const { role } = useSelector(state => state.auth.user);
  const { isAuthenticated } = useSelector(state => state.auth);
  console.log('isAuthenticated',isAuthenticated)
  const myvar = localStorage.getItem("role")

  const routes = [
    {
      path: "/",
      name: "Dashboard",
      icon: <FaHome />,
    },
    {
      path: "/cashiersale",
      name: "Cashier",
      icon: <FaUser />,
    },
    {
      path: "/salehistory",
      name: "Sale History",
      icon: <MdMessage />,
    },
    {
      path: "/category",
      name: "Category",
      icon: <AiTwotoneFileExclamation />,
    },
    {
      path: "/product",
      name: "Prdouct",
      icon: <BsCartCheck />,
    },
    {
      path: "/tax",
      name: "Tax",
      icon: <BsTag />,
    },
    {
      path: "/promotions",
      name: "Promotions",
      icon: <AiTwotoneFileExclamation />,
    },
    {
      path: "/users",
      name: "User",
      icon: <BsCartCheck />,
    },

    {
      path: "/logout",
      name: "Logout",
      icon: <FiLogOut />,
    },

  ];
  const userRoutes = [
    {
      path: "/",
      name: "Dashboard",
      icon: <FaHome />,
    },
    {
      path: "/cashiersale",
      name: "Cashier",
      icon: <FaUser />,
    },


  ];
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);


  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container ">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",
            transition: { duration: 0.5, type: "spring", damping: 10 },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            {/* <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  DoSomeCoding
                </motion.h1>
              )}
            </AnimatePresence> */}

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>

          <section className="routes">
            {
              myvar === 'admin' ? (
                <>
                  {routes.map((route, index) => {
                    return (
                      <NavLink
                        to={route.path}
                        key={index}
                        className="link"
                        activeclassname="active"
                      >
                        <div className="icon">{route.icon}</div>
                        <AnimatePresence>
                          {isOpen && (
                            <motion.div
                              variants={showAnimation}
                              initial="hidden"
                              animate="show"
                              exit="hidden"
                              className="link_text"
                            >
                              {route.name}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </NavLink>
                    );
                  })}
                </>
              ) : (
                <>
                
                {userRoutes.map((route, index) => {
                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className="link"
                      activeclassname="active"
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                })}
                </>
              )
            }

          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
