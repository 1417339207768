import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, updateQuantity } from '../../services/Action/categoryAction';


const Categories = params => {
    const dispatch = useDispatch();
    const { rows } = useSelector(state => state.category);

    useEffect(() => {
        dispatch(getCategory());

    }, [dispatch])
    const data = rows

    // [
    //     { id: 1, name: "John Doe", image: "https://wts.com/wts.com/locations/image-thumb__910__location-header/pakistan.webp" },
    //     { id: 2, name: "John Java", image: "https://wts.com/wts.com/locations/image-thumb__910__location-header/pakistan.webp" },
    //     { id: 3, name: "Wada Doe", image: "https://wts.com/wts.com/locations/image-thumb__910__location-header/pakistan.webp" },
    //     { id: 4, name: "Bewa Doe", image: "https://wts.com/wts.com/locations/image-thumb__910__location-header/pakistan.webp" },
    //     { id: 4, name: "Bewa Doe", image: "https://wts.com/wts.com/locations/image-thumb__910__location-header/pakistan.webp" },
    //     { id: 4, name: "Bewa Doe", image: "https://wts.com/wts.com/locations/image-thumb__910__location-header/pakistan.webp" },
    // ]

    return (
        <div className='categories my-3 px-3'>
            <h1>Categories</h1>
            <div className='wrapper1 '>
                {
                    data && data.map((item, index) => (
                        <div onClick={ () => params.setName(item.id) } >
                             {/* <div onClick={ () => btnCategory(item.id) } > */}
                            <div className="card text-center m-2 py-3 " style={{ width: 10 + 'rem' }}>
 
                                <img className='m-auto ' src={`${process.env.REACT_APP_BASEURL}${item.image}`} alt="Avatar" style={{ width: 100 + 'px' }} />
                                {/* <img className='m-auto ' src={require(`${process.env.REACT_APP_BASEURL}${item.image}`)} alt="Avatar" style={{ width: 100 + 'px' }} /> */}
                                <label>{item.name}</label>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

    )
}

export default Categories