import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { isEmptyArray, isEmptyChildren } from 'formik';
//delte it 1
const MyContext = React.createContext(null);

// const OrderList = ({ itemtorder, setOrder,params }) => {
const OrderList = (props) => {
  const [quantity, setQuantity] = useState()
  const [fdata, setFdata] = useState([]);
  console.log('cheeck ****', fdata)




  const [newFdata2, setNewFdata2] = useState()
  console.log(fdata)
  // console.log('dfsffsdffdata', fdata)
  // const newFdata = fdata.flat()
  // console.log('my type of', typeof (newFdata))
  // console.log(newFdata)


  // setNewFdata2(newFdata)
  useEffect(() => {

    setNewFdata2(fdata.flat());
  }, [fdata]);


  console.log('444444', newFdata2)


  const [input, setInput] = useState(1);
  // order list 
  const myFunc = async () => {
    const id = { 'id': props.itemtorder }
    const data = await axios.post('/api/v1/productbyid/', id)
    console.log('hello data', data)
    return data
  }
  useEffect(() => {
    myFunc()
      .then((res) => {
        const finaldata = res.data.rows
        setFdata([...fdata, finaldata])
      })
  }, [props.itemtorder]);

  const removeItem = (index) => {

    // setNewFdata2(newFdata2.filter((o, i) => index !== i));
    setNewFdata2(newFdata2.filter((item) => item.id !== index));
    setFdata(fdata.flat().filter((item) => item.id !== index));
  };

  console.log("fdata2 ---", newFdata2)
  const handleFormChange = (index, e) => {
    console.log(" index and e", index)
    console.log('newFdata2 inside function', newFdata2)
    let workingObject = newFdata2[index]

    console.log("workingObject", workingObject)
    workingObject.quantity = e.target.value
    workingObject.totalAmount = workingObject.quantity * workingObject.sellingPrice
    newFdata2[index] = workingObject
    setNewFdata2([...newFdata2])
  }
  const g = "i am runinng"
  // setOrder(fdata)
  return (
    <div >

      <div className="row">
        {console.log(typeof (newFdata2))}
        <div className="col-12 d-flex justify-content-end mb-2 pe-5">
          {
           (newFdata2 && Object?.keys(newFdata2)?.length == 0)  ? (
              <> <button className='btn btn-primary invisible ' onClick={() => props.setOrder(newFdata2)}>Generate Bill</button></>
            ) : (
              <>
                <button className='btn btn-primary   ' onClick={() => props.setOrder(newFdata2)}>Generate Bill</button>
              </>
            )
          }

        </div>
        <div className="col-12">
          <table className="table">

            <thead>
              <tr>
                <th scope="col">Item List</th>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Amount</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>

            <tbody className="table-group-divider">
              {


                newFdata2 && newFdata2?.map((index, key) => (
                  <>
                    <tr>

                      {/* {()=>props.setOrder(newFdata2)} */}

                      <td scope="row">{key + 1}</td>
                      <td><input type="number" className='w-25' min="1"
                        value={index.quantity} onChange={(e) => handleFormChange(key, e)} /></td>
                      <td>{index.sellingPrice}</td>
                      <td>{index.totalAmount || index.sellingPrice}</td>

                      <td><button className="btn btn-danger" onClick={() => removeItem(index.id)}>X</button></td>

                    </tr>
                  </>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>


    </div>

  )
}

export default OrderList