import axios from 'axios';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,


    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,

    NEW_USERS_REQUEST,
    NEW_USERS_SUCCESS,
    NEW_USERS_FAIL,
    CLEAR_ERRORS
} from '../Constrants/UserConstrants'

//login 
export const login = (email, password) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/login', { email, password }, config)

        //save token to localstorage

        localStorage.setItem("TOKEN", data.token)
        localStorage.setItem("id", data.user.id)
        localStorage.setItem("role", data.user.role)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data.user
        })
    }
    catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.message
        })
    }
}
export const getUsers = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_USERS_REQUEST })
        const { data } = await axios.get('/api/v1/getusers')
        console.log(typeof (data))
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error.response.data.message
        })

    }
}


export const newUsers = (usersData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_USERS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/addusers`, usersData, config)

        dispatch({
            type: NEW_USERS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_USERS_FAIL,
            payload: error.response.data.message
        })
    }
}
//clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}