import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import {login,clearErrors} from '../../Services/Actions/userAction'
import { login, clearErrors } from '../../services/Action/userActions'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from "./loginSchema";
import axios from 'axios';
//primary blue
//success green
//warning yellow
const Login = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { isAuthenticated, error, loading } = useSelector(state => state.auth);
  useEffect(() => {
    if (isAuthenticated) {

      navigate("/");
    }
    if (error) {
      // alert.error(error);
      dispatch(clearErrors());
    }
  },
    // [dispatch,alert,isAuthenticated,error,navigate]
    [dispatch, isAuthenticated, error, navigate]
  )
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(email, password)
    dispatch(login(email, password))

  };
  const submitForm = async (data) => {
    console.log("datasdsadsd:", data);
    console.log(data.username)
    dispatch(login(data.username,data.password))
    // const url = `/api/v1/addtax`;
    // await axios
    //   .post(url, data)
    //   .then((res) => {
    //     console.log(res);
    //     console.log(res.data.data.role);
    //     console.log("Success !!");

    //     navigate('/tax')
    //     //  notify();
    //   })
      // .catch((error) => {
      //   console.log(error);
      //   // notifyError();
      // });
  };

  return (
    <div className="login-form login-box">
      <div className="container my-4">
        {/* <div className="row">
          <div className="col-12 mb-3">
            <img src="/assets/images/logo.png" alt="About" className="w-25" />
            <h3>LOGIN FORM</h3>
            <label className="mb-2" htmlFor="exampleDropdownFormEmail1">Email address</label>
            <input type="email" className="form-control" id="exampleDropdownFormEmail1" placeholder="email@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            <label className="my-2" htmlFor="exampleDropdownFormPassword1">Password</label>
            <input type="password" className="form-control" id="exampleDropdownFormPassword1" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="col-6">
            <button type="submit" className="btn btn-primary w-100" onClick={onSubmit}>Sign in</button>
          </div>
          <div className="col-6">

            <label className="form-check-label" htmlFor="dropdownCheck">Remember me</label>
          </div>
          <div className="col-12">
            <a className="dropdown-item" href="#">Forgot password?</a>
          </div>




        </div> */}
        <form className="row" onSubmit={handleSubmit(submitForm)}>
          <div className="col-lg-12">
            <img src="/assets/images/logo.png" alt="About" className="w-25" />
            <h3>LOGIN FORM</h3>
          </div>
          <div className="col-lg-12">
            <label className="mb-2" htmlFor="exampleDropdownFormEmail1">Email address</label>
            <input
              type="text"
              placeholder="email@example.com"
              className="form-control"
              {...register("username")}
            />
            <p className="text-sm text-danger ml-3"> {errors.username?.message} </p>
          </div>

          <div className="col-lg-12 ">
            <label className="my-2" htmlFor="exampleDropdownFormPassword1">Password</label>
            <input
              type="text"
              placeholder="Password"
              className="form-control"
              {...register("password")}
            />
            <p className="text-sm text-danger ml-3"> {errors.password?.message} </p>
          </div>






          <div className="col-6">
            <input
              className="btn btn-primary w-100"
              type="submit"
              value="Sign in"
            />
          </div>
          <div className="col-6">

            <label className="form-check-label" htmlFor="dropdownCheck">Remember me</label>
          </div>
          <div className="col-12">
            <a className="dropdown-item ps-0" href="#">Forgot password?</a>
          </div>
        </form>

      </div>

    </div>



  )
}

export default Login