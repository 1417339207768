import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom'
import { getTax, updateQuantity } from '../../services/Action/taxAction'
const PaymentList = (props) => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(state => state.auth);

  const tamount = props?.order?.map(order => order.sellingPrice).reduce((acc, order) => order + acc);


  const dispatch = useDispatch();
  const { rows } = useSelector(state => state.tax);
console.log(rows)

  console.log('My data is ', rows?.[0].taxValue)
  useEffect(() => {

    setLoading(true);
    dispatch(getTax());
    setLoading(false);

  }, [])

  const navigate = useNavigate();


  const [discount, setDiscount] = useState(0)
  const [payment, setPayment] = useState()
  const handleChange = e => {
    let { name, value } = e.target
    setDiscount({
      ...discount,
      [name]: value
    })
  }

  console.log(discount.Discount)

  const TotalItems = props?.order?.length
  const SubTotal = tamount // it will come from order
  const Witholding = rows?.[0].taxValue// it will come from database

  const GST = (rows?.[0].taxValue / 100) * SubTotal || 0//it will come from tax tabel
  const TotalTax = Witholding + GST
  const AmountAfterTax = SubTotal + TotalTax || 0
  const Discount = discount.Discount || 0 //it eill come from input
  const DiscountAmount = (Discount / 100) * SubTotal || 0

  const TotalPayment = AmountAfterTax - DiscountAmount || 0

  const checkout = async (TotalItems, SubTotal, Witholding, GST, TotalTax,
    AmountAfterTax, Discount, DiscountAmount, TotalPayment) => {
    let url = '/api/v1/addtransaction';
    const data = {
      cashierId: user.id,
      cashierName: user.name,
      SubTotal: SubTotal,
      Witholding: Witholding,
      Gst: GST,
      TotalItems: TotalItems,
      TotalTax: TotalTax,
      AmountAfterTax: AmountAfterTax,
      Discount: Discount,
      DiscountAmount: DiscountAmount,
      TotalPayment: TotalPayment
    }
    try {
      let response = await axios.post(url, data)
        .then(() => {

          navigate('/printpage', { state: { data } })
        }
        )
    } catch (error) {

    }


    // window.print()

  }
  return (
    <div id="PayemntLsit" className='p-3 '>
      <table className="table  text-white">

        <tr>
          <td scope="col" >Total Items</td>
          <td scope="col">{TotalItems}</td>
        </tr>
        <tr>
          <td>Sub Total:</td>
          <td value={SubTotal}>{SubTotal}</td>
          <td>PKR</td>
        </tr>
        <tr>
          <td>Witholding</td>
          <td>{Witholding}</td>
          <td>PKR</td>
        </tr>
        <tr>
          <td>GST</td>
          <td>{GST}</td>
          <td>%</td>
        </tr>
        <tr>
          <td>Total Tax:</td>
          <td>{TotalTax}</td>
          <td>PKR</td>
        </tr>
        <tr>
          <td>Amount <span>(After Tax):</span></td>
          <td>{AmountAfterTax}</td>
          <td>PKR</td>
        </tr>
        <tr>
          <td>Discount <span>(%):</span></td>
          <td><input className='bg-white' name="Discount" onChange={handleChange} /></td>
          <td>%</td>
        </tr>
        <tr>
          <td>Discount <span>(amount):</span></td>
          <td>{DiscountAmount}</td>
          <td>PKR</td>
        </tr>

        <tr>
          <td>Total Payment</td>
          <td>{TotalPayment}</td>
          <td>PKR</td>
        </tr>
      </table>
      <div className='d-flex justify-content-end pe-5'>
        <button className='btn btn-success' onClick={() => checkout(TotalItems, SubTotal, Witholding, GST, TotalTax, AmountAfterTax, Discount, DiscountAmount, TotalPayment)}>Checkout</button>
      </div>

    </div>
  )
}

export default PaymentList