//product Action
import axios from 'axios';
import {
    GET_TAXES_BY_CATEGORY_REQUEST,
    GET_TAXES_BY_CATEGORY_SUCCESS,
    GET_TAXES_BY_CATEGORY_SUCCESS_FAIL,
    ALL_TAX_REQUEST,
    ALL_TAX_SUCCESS,
    ALL_TAX_FAIL,


    CLEAR_ERRORS
} from '../Constrants/TaxConstrants'

export const getTax= () =>async (dispatch)=>{
    try {
        dispatch({type: ALL_TAX_REQUEST})
        const{ data } = await axios.get('/api/v1/taxes')
    
        dispatch({
            type: ALL_TAX_SUCCESS,
            payload: data
        })
         
    } catch (error) {
        dispatch({
            type:ALL_TAX_FAIL,
            payload: error.response.data.message
        })
        
    }
}
// export const getProductByCategory = (categoryId_fk) => async(dispatch)=>{
//     console.log(categoryId_fk)
//     try {
//         dispatch({type: GET_PRODUCTS_BY_CATEGORY_REQUEST})
//         const{ data } = await axios.get(`/api/v1/catproduct/${categoryId_fk}`)
//         console.log(data)
//         dispatch({
//             type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
//             payload: data
//         })

//     } catch (error) {
//         dispatch({
//             type:GET_PRODUCTS_BY_CATEGORY_SUCCESS_FAIL,
//             payload: error.response.data.message
//         })
//     }
// }