import React from "react";
const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const PageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    PageNumbers.push(i);
  }
  return (
    <div className="d-flex justify-content-center">
      <nav>
        <ul className="pagination">
          {PageNumbers.map((number) => (
            <li className="page-item" key={number}>
              <a
                onClick={() => paginate(number)}
                // href="!#"
                className="page-link"
              >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
export default Pagination;