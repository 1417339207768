import React, { useState } from 'react'

const Posts = ({ posts, loading }) => {
    const [detailHistory,setDetailHistory] = useState()
    console.log('&&&&posts****', detailHistory)
    if (loading) {
        return <h1>Loading</h1>;
    }
    const saleHistory = (value) => {
        console.log('ttttoooo', value)
        // setInput(value)
        setDetailHistory(value)
      
    }
    const print = () =>{
        window.print()
    }

    return (
        <>

        <table className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Bill Number</th>
                        <th scope="col">Date</th>

                        <th scope="col">Sold By</th>
                        <th scope="col">TotalPayment</th>

                        <th scope="col">Detail</th>

                    </tr>
                </thead>
                <tbody >
                    {

                        posts && posts?.map((item) => (
                            <tr key={item.id} className="p-5">
                                <td scope="row">{item.id}</td>
                                <td>{item.transactionDate}</td>
                                <td>{item.cashierName}</td>
                                <td>{item.totalPayment}</td>
                                <td>
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                        value={item.productname}
                                        onClick={() => { saleHistory(item) }}
                                    >Detail</button>
                                </td>
                            </tr>
                        ))
                    }


                </tbody>
            </table>
            </table>
            <div className="row">
                <div className='col-lg-12'>
                    {  console.log('&&&&posts****', detailHistory)}
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Sale History</h5>
                                </div>
                                <div className="modal-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sale History</th>
                                                {/* <th scope="col">First</th>
                                                <th scope="col">Last</th>
                                                <th scope="col">Handle</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Bill #</th>
                                                <td>{detailHistory?.id}</td>
                                                <th scope="row">Cashier Name</th>
                                                <td>{detailHistory?.cashierName}</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Bill #</th>
                                                <td>{detailHistory?.id}</td>
                                                <th scope="row">Cashier Name</th>
                                                <td>{detailHistory?.cashierName}</td>
                                            </tr>
                                            <tr>
                                            <th scope="row">Bill #</th>
                                                <td>{detailHistory?.id}</td>
                                                <th scope="row">Cashier Name</th>
                                                <td>{detailHistory?.cashierName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                    // onClick={handleDownload}
                                    >Download</button>
                                    <button type="button" className="btn btn-secondary"  onClick={()=>print()}>Print</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Posts