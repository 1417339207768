import axios from 'axios'
import { Button } from 'bootstrap'
import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik'
import * as Yup from 'yup'

const AddItem = () => {

  const [product, setProduct] = useState({
    name: "",
    categoryId_fk: "",
    description: "",
    buyingPrice: "",
    sellingPrice: "",
    discountedPrice: "",
    barcode: "",
    image: ""
  })
  const imageUpload = (event) => {
    console.log(event.target.files[0])
    setProduct({ ...product, image: event.target.files[0] })

  }
  console.log(product)
  const handleChange = e => {
    let { name, value } = e.target
    setProduct({
      ...product,
      [name]: value
    })
  }

  const addProduct = async (e) => {
    
    e.preventDefault();
    let url = '/api/v1/addproduct';
    console.log('===', product.image, "===", product.image.name)
    const formdata = new FormData()
    //for formdata
    formdata.append('myFile', product.image, product.image.name)
    formdata.append('name', product.name)
    formdata.append('categoryId_fk', product.categoryId_fk)
    formdata.append('description', product.description)
    formdata.append('buyingPrice', product.buyingPrice)
    formdata.append('sellingPrice', product.sellingPrice)
    formdata.append('discountedPrice', product.discountedPrice)
    formdata.append('barcode', product.barcode)
    try {
      console.log(formdata)
      let response = await axios.post(url, formdata)
      console.log(response)
      if (response.status == 200) {
        console.success('Added Successfully...')
      }
    } catch (error) {
      console.error('Something went wrong')

    }
    return
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>Add Product</h1>
        </div>
      </div>
      <form enctype="multipart/form-data">
        <div className="row">
          <div className="col-lg-3">
            {console.log("product", product)}
            <label>
              <h5>Name</h5>
            </label>
            {/* 1 */}
            <input type="text" className="form-control form " placeholder="Enter Name" aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg" 
              name="name" 
              value={product.name} 
              onChange={handleChange}
              required={true} />
          </div>
          <div className="col-lg-3">
            <label>
              <h5>Code</h5>
            </label>
            {/* 2 */}
            <input type="text" className="form-control form " placeholder="Enter Code" aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg" name="categoryId_fk" value={product.categoryId_fk} onChange={handleChange} />
          </div>

          <div className="row">
            <div className="col-lg-6 pe-0 mt-3">
              <label>
                <h5>Description</h5>
              </label>
              {/* 4 */}
              <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Add Description" rows="5"
                name="description" value={product.description} onChange={handleChange} ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pe-0 mt-3">
              <label>
                <h5>Buying Price</h5>
              </label>
              {/* 4 */}
              <input type="text" className="form-control form " placeholder="Enter Code" aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg" name="buyingPrice" value={product.buyingPrice} onChange={handleChange} />
            </div>
            <div className="col-lg-6 pe-0 mt-3">
              <label>
                <h5>Selling Price</h5>
              </label>
              {/* 4 */}
              <input type="text" className="form-control form " placeholder="Enter Code" aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg" name="sellingPrice" value={product.sellingPrice} onChange={handleChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pe-0 mt-3">
              <label>
                <h5>Discounted Price</h5>
              </label>
              {/* 4 */}
              <input type="text" className="form-control form " placeholder="Enter Code" aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg" name="discountedPrice" value={product.discountedPrice} onChange={handleChange} />
            </div>
            <div className="col-lg-6 pe-0 mt-3">
              <label>
                <h5>Barcode</h5>
              </label>
              {/* 4 */}
              <input type="text" className="form-control form " placeholder="Enter Code" aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg" name="barcode" value={product.barcode} onChange={handleChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className='form-label'>Upload Profile</label>
                <input type="file" className='form-control' name="myFile" autoComplete='off' onChange={imageUpload}></input>
              </div>
            </div>
            <div className="col-4 mt-3">
              <button className="btn  btn-primary Add-1" type="button" onClick={addProduct}>Add</button>
            </div>
          </div>



        </div>
      </form>

    </>
  )
}



export default AddItem