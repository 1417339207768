import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
    NEW_CATEGORY_REQUEST,
    NEW_CATEGORY_SUCCESS,
    NEW_CATEGORY_FAIL,
    NEW_CATEGORY_RESET,
    UPDATE_CATEGORY,

    CLEAR_ERRORS
} from '../Constrants/CategoryConstrants'
export const categoryReducer = (state = { categories : [] }, action) => {
    switch (action.type) {
        case ALL_CATEGORY_REQUEST:
            return {
                loading: true,
                categories: []
            }
        case ALL_CATEGORY_SUCCESS:
            return {
                loading: false,
                rows: action.payload.rows,
                // categoriesCount: action.payload.categoriesCount
            }
        // case UPDATE_QUANTITY:
        //     const { index, quantity } = action.payload;
        //     const prods = state.products.map((p, i) => {
        //         if (i !== index)
        //             return p;
        //         return {
        //             ...p,
        //             quantity
        //         }
        //     });
        //     return {
        //         loading: true,
        //         products: prods
        //     }

        case ALL_CATEGORY_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const newProductReducer = (state = { category: {} }, action) => {
    switch (action.type) {

        case NEW_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_CATEGORY_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                product: action.payload.product
            }

        case NEW_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_CATEGORY_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}