
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { productSchema } from "./productSchema";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, updateQuantity } from '../../services/Action/categoryAction';
const AddProduct = () => {
    const navigate = useNavigate();
    const [category, setCategory] = useState()
    useEffect(() => {

        getCategoryFun()

        //    .then(response => {
        //         console.log(response.data);
        //         setCategory({
        //             categoryData: response.data
        //         });
        //     });

    }, [])


    const getCategoryFun = async () => {
        await axios.get('/api/v1/getcategory-list')
            .then(response => {
                console.log(response.data);
                setCategory({
                    categoryData: response.data
                });
            });
    }
    console.log('all category are here please check', category)
    const [product, setProduct] = useState({
        image: ""
    })
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(productSchema),
    });
    console.log('verfiy register data',register)
    const imageUpload = (event) => {
        console.log(event.target.files[0])
        setProduct({ ...product, image: event.target.files[0] })

    }


    const submitForm = async (data) => {
        const btn = document.getElementById('myBtn');
        btn.style.visibility = 'hidden';
        let url = '/api/v1/addproduct';
        console.log(data.myFile)

        const formdata = new FormData()

        formdata.append('myFile', product.image, product.image.name)
        formdata.append('name', data.name)
        formdata.append('categoryId_fk', data.categoryId_fk)
        formdata.append('description', data.description)
        formdata.append('buyingPrice', data.buyingPrice)
        formdata.append('sellingPrice', data.sellingPrice)
        formdata.append('discountedPrice', data.discountedPrice)
        formdata.append('barcode', data.barcode)
        try {
            console.log(formdata)
            let response = await axios.post(url, formdata)
                .then(() => {
                    console.log('it is in toast domain')
                    toast.success('Added SuccessFully...', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                
                
                )
                .finally(setTimeout(function () {
                    navigate('/product')
                }, 3000))

            console.log(response)
            if (response.status == 200) {
                console.success('Added Successfully...')
            }
        } catch (error) {
            console.error('Something went wrong')

        }
        return

    };
    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <form className="row bg-hite" onSubmit={handleSubmit(submitForm)}>
                <div className="col-12">
                    <h1>Add Product</h1>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Product Name</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Product Name"
                        className="form-control form"
                        {...register("name")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.name?.message} </p>
                </div>
                <div className="col-lg-3 ">
                    <label>
                        <h5>Category</h5>
                    </label>
                    <select className="form-select form" aria-label="Default select example"  {...register("categoryId_fk")}>
                        <option selected>Select Category</option>
                        {
                            category && category.categoryData.rows.map((item, index) => (
                                <option value={item.id}>{item.name}</option>
                          
                            ))
                        }
                    </select>
                    {/* <input
                        type="text"
                        placeholder="Enter Category"
                        className="form-control form"
                        {...register("categoryId_fk")}
                    /> */}
                    <p className="text-sm text-danger ml-3"> {errors.categoryId_fk?.message} </p>
                </div>
                <div className="col-lg-3 ">
                    <label>
                        <h5>Description</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Description"
                        className="form-control form"
                        {...register("description")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.description?.message} </p>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Buying Price</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Buying Price"
                        className="form-control form"
                        {...register("buyingPrice")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.buyingPrice?.message} </p>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Selling Price</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Selling Price"
                        className="form-control form"
                        {...register("sellingPrice")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.sellingPrice?.message} </p>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Discounted Price</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Discounted Price"
                        className="form-control form"
                        {...register("discountedPrice")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.discountedPrice?.message} </p>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Barcode</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter BarCode"
                        className="form-control form"
                        {...register("barcode")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.barcode?.message} </p>
                </div>
                <div className="col-lg-3">
                    <div className="mb-3">
                        <label className='form-label'>Upload Profile</label>
                        <input
                            type="file"
                            placeholder="Enter Image"
                            className="form-control form"
                            onChange={imageUpload}
                        // {...register("myFile")}
                        />
                    </div>

                    <p className="text-sm text-danger ml-3"> {errors.myFile?.message} </p>
                </div>





                <div className="col-4 mt-3">
                    <input id='myBtn'
                        className="btn btn-primary Add-1"
                        type="submit"
                        value="Add"
                    />
                </div>
            </form>
            <ToastContainer />
        </div>




    );

};
export default AddProduct;