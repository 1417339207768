import React, { useEffect, useReducer, useState } from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import { useDispatch, useSelector } from 'react-redux';
import Posts from './Posts';
import { getUsers, updateQuantity } from '../../services/Action/userActions'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Search from './Search';
const Users = () => {
    const navigate = useNavigate();
    //for pagination
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState(5)
    const [userscopy, setUserscopy] = useState()
    const [name, setName] = useState(0);
    //for redux area to fetch data ON
    const dispatch = useDispatch();
    const { rows } = useSelector(state => state.users);
    const [reload,setRelod] =useState()
    useEffect(() => {
        setLoading(true);
        dispatch(getUsers());
        setLoading(false);

    }, [dispatch,reload], name)
    //for redux area to fetch Off 


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = userscopy && userscopy.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = pageNumber => setCurrentPage(pageNumber);

    //go to add foam 
    const goToNextPage = () => {
        navigate('/addusers')
    }
    useEffect(() => {
        setUserscopy(rows)
    }, [rows])
    const searchHandler = (data) => {
        // data.preventDefault()
        console.log('****()****', data)
        const filterUsers = rows.filter(obj => obj?.name?.includes(data))
        setUserscopy(filterUsers)
    }
    const headers = [
        { label: "Users Name", key: "name" },
        { label: "Users Code ", key: "code" },
        { label: "Users Id", key: "UsersId" },
        { label: "Description", key: "description" }
    ];
    const csvLink = {
        filename: 'Users.csv',
        headers: headers,
        data: rows
    }
    return (
        <div className='container-fluid py-5 px-5 bg-white  full-height'>
            <div className="row ">
                <div className="col-lg-2">
                    <button className="btn btn-primary mb-4" onClick={goToNextPage}>Add Item</button>
                </div>

            </div>
            <div className="row">
                <div className="col-lg-5">
                    <h1>Users</h1>
                </div>
                <div className="col-lg-2 ps-1 mb-3">
                    {
                        rows &&
                        <CSVLink {...csvLink} className="btn btn-primary mx-2">CSV</CSVLink>
                    }
                </div>
                <div className="col-lg-5 ">
                    <Search searchHandler={searchHandler} />
            
                </div>
                <Posts posts={currentPosts} loading={loading} setName={setName} setRelod={setRelod}/>
                <Pagination postsPerPage={postsPerPage} totalPosts={rows && rows?.length} paginate={paginate} />

            </div>
            <ToastContainer />
        </div>
    )
}

export default Users