export const ALL_CATEGORY_REQUEST = 'ALL_CATEGORY_REQUEST'
export const ALL_CATEGORY_SUCCESS = 'ALL_CATEGORY_SUCCESS'
export const ALL_CATEGORY_FAIL = 'ALL_CATEGORY_FAIL'
export const UPDATE_CATEGORY= 'UPDATE_CATEGORY'



export const ALL_CATEGORY_REQUEST_BY_ID = 'ALL_CATEGORY_REQUEST_BY_ID'
export const ALL_CATEGORY_SUCCESS_BY_ID = 'ALL_CATEGORY_SUCCESS_BY_ID'
export const ALL_CATEGORY_FAIL_BY_ID = 'ALL_CATEGORY_FAIL_BY_ID'
export const UPDATE_CATEGORY_BY_ID = 'UPDATE_CATEGORY_BY_ID'


export const NEW_CATEGORY_REQUEST = 'NEW_CATEGORY_REQUEST'
export const NEW_CATEGORY_SUCCESS = 'NEW_CATEGORY_SUCCESS'
export const NEW_CATEGORY_FAIL = 'NEW_CATEGORY_FAIL'
export const NEW_CATEGORY_RESET = 'NEW_CATEGORY_RESET'


export const CLEAR_ERRORS ='CLEAR_ERRORS'

export const CLEAR_ERRORS_BY_ID ='CLEAR_ERRORS_BY_ID'
