import * as yup from "yup";
export const categorySchema = yup.object().shape({
    name: yup.string().required('Category Name is required'),
    code: yup.string().required('CategoryId_fk is required'),
    description: yup.string().required('Description is required'),


});
export const imageSchema = yup.object().shape({

    myFile: yup.string().required('Image is required'),


});