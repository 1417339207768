import axios from 'axios'
import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const EditTax = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tax, setTax] = useState({
        id: location.state.value,
        taxName: "",
        type: "",
        description: "",
        taxValue:"",
     
    })

    console.log(location.state.value)
    // it is fecthing data by id 
    const myFunc = async () => {
        const getData = location.state.value
        const id = { 'id': getData }
        const data = await axios.post('/api/v1/getspecifictax', id)
        return data
    }
    useEffect(() => {
        myFunc()
            .then((res) => {
                const finaldata = res.data.rows[0]
                setTax(finaldata)
                console.log(finaldata)

            })
    }, [location]);

    const imageUpload = (event) => {
        console.log(event.target.files[0])
        setTax({ ...tax, image: event.target.files[0] })

    }
    console.log(tax)
    const handleChange = e => {
        let { name, value } = e.target
        setTax({
            ...tax,
            [name]: value
        })
    }

    const EditTax = async (e) => {
        e.preventDefault();
       
        let url = '/api/v1/updatetax';
        // console.log('===', tax.image, "===", product.tax.name)
        // const formdata = new FormData()
        // console.log(formdata)
        // formdata.append('myFile', tax.image, tax.image.name)
        // console.log(formdata)
        // formdata.append('id', tax.id)
        // formdata.append('name', tax.taxName)
        // formdata.append('categoryId_fk', tax.type)
        // formdata.append('description', tax.description)
        // formdata.append('buyingPrice', tax.buyingPrice)
        // formdata.append('sellingPrice', tax.sellingPrice)
        // formdata.append('discountedPrice', tax.discountedPrice)
        // formdata.append('barcode', tax.barcode)
        try {
            // console.log(formdata)
            let response = await axios.put(url, tax)
            .then(()=>{
                console.log('it is in toast domain')
                toast.success('Edit SuccessFully...', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
            .finally(setTimeout(function(){
                navigate('/tax')
            },3000))
            
            if (response.status == 200) {
                console.success('Added Successfully...')

            }

        } catch (error) {
            console.error('Something went wrong')
        }
        return
    }
    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <div className="row">
                <div className="col-12">
                    <h1>Edit Tax</h1>
                </div>
            </div>
            <form enctype="multipart/form-data">
                <div className="row">
                    <div className="col-lg-3">
                        {console.log("category", tax)}
                        <label>
                            <h5>Name</h5>
                        </label>
                        {/* 1 */}
                        <input type="text" className="form-control form " placeholder="Enter Name" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="taxName" value={tax.taxName} onChange={handleChange} />
                    </div>
                    <div className="col-lg-3">
                        <label>
                            <h5>Type</h5>
                        </label>
                        {/* 2 */}
                        <input type="text" className="form-control form " placeholder="Enter Tax Type" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="type" value={tax.type} onChange={handleChange} />
                    </div>
                    <div className="col-lg-3">
                        <label>
                            <h5>Value</h5>
                        </label>
                        {/* 2 */}
                        <input type="text" className="form-control form " placeholder="Enter Tax Value" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="taxValue" value={tax.taxValue} onChange={handleChange} />
                    </div>


                    <div className="col-lg-6 pe-0 ">
                        <label>
                            <h5>Description</h5>
                        </label>
                        {/* 4 */}
                        <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Add Description" rows="5" name="description" value={tax.description} onChange={handleChange} ></textarea>
                    </div>
               

                    <div className="row">
                  
                        <div className="col-4 mt-3">
                            <button className="btn  btn-primary Add-1" type="button" onClick={EditTax}>Add</button>
                        </div>
                    </div>


                </div>
            </form>
            <ToastContainer />
            </div>
    )
}



export default EditTax