import * as yup from "yup";
export const productSchema = yup.object().shape({
    name: yup.string().required('Product Name is required'),
    categoryId_fk: yup.string().required('CategoryId_fk is required'),
    description: yup.string().required('Description is required'),
    buyingPrice: yup.string().required('Buying Price is required'),
    sellingPrice: yup.string().required('Selling Price is required'),
    discountedPrice: yup.string().required('Discounted Price Price is required'),
    barcode: yup.string().required('Barcode  is required')  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  //   "Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character"
  // ),

});
export const imageSchema = yup.object().shape({

    myFile: yup.string().required('Image is required'),
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  //   "Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character"
  // ),

});