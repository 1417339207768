import axios from 'axios'
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Posts = ({ posts, loading,setRelod }) => {
    let myposts = posts
    const [refresh, setRefresh] = useState(1)
    const navigate = useNavigate();
    if (loading) {
        return <h2>loading....</h2>
    }
    const headers = {
        'Authorization': 'refresh'

    }
    const data = {
        foo: 'bar'
    }

    //delete function is working
    const deleteCategory = async (id) => {
      
        try {
            const res = await axios.delete(`/api/v1/deletecategory/${id}`)

                .then((res) => {
                    toast.error("Category has been Deleted", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(() => {
                    toast.error("sorry only admin can insert", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                // .finally(() => {
                //     console.log('this is ****', id)
                //     myposts = posts.filter(obj => obj?.id!==id)
                 
                // })
            console.log(res)
        } catch (error) {
            console.log(error)
        }
        setRelod(id)
        // dispatch(getProduct());
        // res.data.json;
    }

    const goToEditPage = (value) => {
        navigate('/editcategory', { state: { value } })
        // navigate('/editcategory')
    }
    return (
        <div className="col-lg-12">
            <table className="table">
                <thead>
                    <tr>

                        <th scope="col">Category Name</th>
                        <th scope="col">Code</th>
                        <th scope="col">Description</th>

                        {/* <th scope="col">Print Bar Code</th> */}
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        posts && posts.map((item) => (
                            <tr key={item.id} className="p-5">
                                <td scope="row">{item.name}</td>
                                <td>{item.code}</td>
                                <td>{item.description}</td>

                                {/* <td><button className="btn btn-primary">Detail</button></td> */}
                                <td><button className="btn btn-primary" value={item.id}
                                    onClick={(e) => { goToEditPage(e.target.value) }}
                                >Edit</button></td>
                                {/* <td><button className="btn btn-primary" onClick={(e) => { goToEditPage() }}>Edit</button></td> */}
                                <td><button className="btn btn-danger" value={item.id}
                                    onClick={(e) => { deleteCategory(e.target.value) }}>Delete</button></td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Posts