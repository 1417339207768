import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { promotionsSchema } from "./promotionsSchema";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const AddPromotions = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(promotionsSchema),
    });

    const navigate = useNavigate();
    const submitForm = async (data) => {
        console.log("here data:", data);
        const url = `/api/v1/addpromotions`;
        await axios
            .post(url, data)
            .then(() => {
                console.log('it is in toast domain')
                toast.success('Added SuccessFully...', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            
            
            )
            .finally(setTimeout(function () {
                navigate('/promotions')
            }, 3000))
    };

    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <form className="row bg-hite" onSubmit={handleSubmit(submitForm)}>
                <div className="col-12">
                    <h1>Add Promotions</h1>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Promotions Name</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Product Name"
                        className="form-control form"
                        {...register("promotion_name")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.promotion_name?.message} </p>
                </div>
                <div className="row">
                <div className="col-lg-6 ">
                    <label>
                        <h5>Description</h5>
                    </label>
                    
                    <input
                        type="text"
                        placeholder="Enter Description"
                        className="form-control form"
                        {...register("description")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.description?.message} </p>
                </div>
                </div>

              




                <div className="row">
                    <div className="col-4 mt-3">
                        <input
                            className="btn btn-primary Add-1"
                            type="submit"
                            value="Add"
                        />
                    </div>
                </div>


            </form>
            <ToastContainer />
        </div>




    );

};
export default AddPromotions;