
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { categorySchema } from "./categorySchema";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const AddCategory = () => {
    const navigate = useNavigate();
    const [category, setCategory] = useState({image: ""})
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(categorySchema),
    });
    const imageUpload = (event) => {
        console.log(event.target.files[0])
        setCategory({ ...category, image: event.target.files[0] })

    }


    const submitForm = async (data) => {
        const btn = document.getElementById('myBtn');
        btn.style.visibility = 'hidden';
        console.log("data:", data);
        let url = '/api/v1/addcategory';
        console.log(data.myFile)

        const formdata = new FormData()

        formdata.append('myFile', category.image, category.image.name)
        formdata.append('name',data.name)
        formdata.append('code', data.code)
     
        formdata.append('description', data.description)
 
        try {
            console.log(formdata)
            let response = await axios.post(url, formdata)
            .then(()=>{
                console.log('it is in toast domain')
                toast.success('Added SuccessFully...', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
            .finally(setTimeout(function(){
                navigate('/category')
            },3000))
            console.log(response)
            if (response.status == 200) {
                console.success('Added Successfully...')
            }
        } catch (error) {
            console.error('Something went wrong')

        }
        return

    };
    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <form className="row bg-hite" onSubmit={handleSubmit(submitForm)}>
                <div className="col-12">
                    <h1>Add Category</h1>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Category Name</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Category Name"
                        className="form-control form"
                        {...register("name")}
                    />
                     <p className="text-sm text-danger ml-3"> {errors.name?.message} </p>
                </div>
                <div className="col-lg-3 ">
                    <label>
                        <h5>Code</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Category"
                        className="form-control form"
                        {...register("code")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.code?.message} </p>
                </div>
                <div className="col-lg-3 ">
                    <label>
                        <h5>Description</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Description"
                        className="form-control form"
                        {...register("description")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.description?.message} </p>
                </div>
           
                <div className="col-lg-3">
                    <div className="mb-3">
                        <label className='form-label'>Upload Profile</label>
                        <input
                            type="file"
                            placeholder="Enter Image"
                            className="form-control form"
                            onChange={imageUpload}
                        // {...register("myFile")}
                        />
                    </div>

                    <p className="text-sm text-danger ml-3"> {errors.myFile?.message} </p>
                </div>





                <div className="col-4 mt-3">
                    <input id='myBtn'
                        className="btn btn-primary Add-1"
                        type="submit"
                        value="Add"
                        
                    />
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};
export default AddCategory;