export const ALL_PROMOTIONS_REQUEST = 'ALL_PROMOTIONS_REQUEST'
export const ALL_PROMOTIONS_SUCCESS = 'ALL_PROMOTIONS_SUCCESS'
export const ALL_PROMOTIONS_FAIL = 'ALL_PROMOTIONS_FAIL'
export const UPDATE_PROMOTIONS= 'UPDATE_PROMOTIONS'



export const ALL_PROMOTIONS_REQUEST_BY_ID = 'ALL_PROMOTIONS_REQUEST_BY_ID'
export const ALL_PROMOTIONS_SUCCESS_BY_ID = 'ALL_PROMOTIONS_SUCCESS_BY_ID'
export const ALL_PROMOTIONS_FAIL_BY_ID = 'ALL_PROMOTIONS_FAIL_BY_ID'
export const UPDATE_PROMOTIONS_BY_ID = 'UPDATE_PROMOTIONS_BY_ID'


export const NEW_PROMOTIONS_REQUEST = 'NEW_PROMOTIONS_REQUEST'
export const NEW_PROMOTIONS_SUCCESS = 'NEW_PROMOTIONS_SUCCESS'
export const NEW_PROMOTIONS_FAIL = 'NEW_PROMOTIONS_FAIL'
export const NEW_PROMOTIONS_RESET = 'NEW_PROMOTIONS_RESET'


export const CLEAR_ERRORS ='CLEAR_ERRORS'

export const CLEAR_ERRORS_BY_ID ='CLEAR_ERRORS_BY_ID'
