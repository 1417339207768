import React, { useState } from 'react'
import Categories from './Categories'
import ItemList from './ItemList'
import OrderList from './OrderList'
import PaymentList from './PaymentList'

const CashierSale = () => {
  const [name, setName] = useState(0);

  const [itemtorder,setItemtorder] = useState(0)

  const [order,setOrder]= useState()
  console.log('orderorderorder',order)
  
  return (
    <div className='container-fluid bg-secondary  h-100 w-100'>
      <div className="row">
        <div className="col-lg-6 col-md-12 px-5 py-3">
    
            <div className="  background m-1 bg-white " ><Categories setName={setName}/></div>
            <div className=" background m-1  bg-white h-50" ><ItemList myData={name} setItemtorder={setItemtorder}/></div>
    
        </div>
        <div className="col-lg-6  px-5 py-3">
  
            <div className=" background m-1  bg-white p-3 h-50"><OrderList itemtorder={itemtorder} setOrder={setOrder} /></div>
            <div className=" background m-1 "><PaymentList order={order}/></div>
       
        </div>
      </div>
    </div>
  )
}

export default CashierSale