export const ALL_SALEHISTORY_REQUEST = 'ALL_SALEHISTORY_REQUEST'
export const ALL_SALEHISTORY_SUCCESS = 'ALL_SALEHISTORY_SUCCESS'
export const ALL_SALEHISTORY_FAIL = 'ALL_SALEHISTORY_FAIL'
export const UPDATE_SALEHISTORY= 'UPDATE_SALEHISTORY'



export const ALL_SALEHISTORY_REQUEST_BY_ID = 'ALL_SALEHISTORY_REQUEST_BY_ID'
export const ALL_SALEHISTORY_SUCCESS_BY_ID = 'ALL_SALEHISTORY_SUCCESS_BY_ID'
export const ALL_SALEHISTORY_FAIL_BY_ID = 'ALL_SALEHISTORY_FAIL_BY_ID'
export const UPDATE_SALEHISTORY_BY_ID = 'UPDATE_SALEHISTORY_BY_ID'


export const NEW_SALEHISTORY_REQUEST = 'NEW_SALEHISTORY_REQUEST'
export const NEW_SALEHISTORY_SUCCESS = 'NEW_SALEHISTORY_SUCCESS'
export const NEW_SALEHISTORY_FAIL = 'NEW_SALEHISTORY_FAIL'
export const NEW_SALEHISTORY_RESET = 'NEW_SALEHISTORY_RESET'


export const CLEAR_ERRORS ='CLEAR_ERRORS'

export const CLEAR_ERRORS_BY_ID ='CLEAR_ERRORS_BY_ID'
