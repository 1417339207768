import axios from 'axios'
import React, { useRef, useState } from 'react'
import Barcode from 'react-barcode';
import { Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Posts = ({ posts, loading, sorting,setRelod }) => {
    const svgRef = useRef(null);

    const [input, setInput] = useState();

    const [format, setFormat] = useState("CODE39");


    const navigate = useNavigate();
    if (loading) {
        return <h2>loading....</h2>
    }
    //delete function 
    const deleteProduct = async (id) => {
        try {
            const res = await axios.delete(`/api/v1/deleteproduct/${id}`)
                .then(() => {

                    toast.error("Product has been Deleted", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(() => {
                    toast.error("sorry only admin can insert", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                });
        } catch (error) {
            console.log(error)
        }
        setRelod(id)
    }
    const goToEditPage = (value) => {
        navigate('/editproduct', { state: { value } })
        // navigate('/editcategory')
    }
    const barcode = (value) => {
     
        setInput(value)

    }


    const handleDownload = () => {
        const preface = '<?xml version="1.0" standalone="no"?>\r\n';
        const svgElement = svgRef.current.refs.renderElement.outerHTML;
        const svgBlob = new Blob([preface, svgElement], {
            type: "image/svg+xml;charset=utf-8"
        });
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "barcode.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    return (
        <div className="col-lg-12">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" onClick={() => sorting("name")}>Product Name</th>
                        <th scope="col">Category Name</th>
                        <th scope="col" onClick={() => sorting("buyingPrice")}>Buying Price</th>
                        <th scope="col">Selling Price</th>
                        <th scope="col">Discounted Price</th>
                        <th scope="col">Print Bar Code</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        posts && posts.map((item) => (
                            <tr key={item.id} className="p-5">
                                <td scope="row">{item.productname}</td>
                                <td>{item.categoryname}</td>
                                <td>{item.buyingPrice}</td>
                                <td>{item.sellingPrice}</td>
                                <td>{item.discountedPrice}</td>
                                {/* <td><button className="btn btn-primary">Detail</button></td> */}
                                <td>
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                        value={item.productname} onClick={(e) => { barcode(e.target.value) }}>Detail</button>
                                </td>
                                <td><button className="btn btn-primary" value={item.id}
                                    onClick={(e) => { goToEditPage(e.target.value) }}
                                >Edit</button></td>
                                <td><button className="btn btn-danger" value={item.id}
                                    onClick={(e) => { deleteProduct(e.target.value) }}>Delete</button></td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="row">
                <div className='col-lg-12'>
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">BarCode</h5>
                                </div>
                                <div className="modal-body">
                                    <Barcode
                                        ref={svgRef}
                                        value={input}// displayValue={false} 
                                        height={90}
                                        format={format}
                                        font="Avenir Next"
                                        fontOptions="600"
                                        textMargin={4}
                                        margin={0} />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleDownload}>Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Posts