import axios from 'axios'
import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const EditCategory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [category, setCategory] = useState({
        id:location.state.value,
        name: "",
        code: "",
        description: "",
        image: ""
    })

    console.log(location.state.value)
    // it is fecthing data by id 
    const myFunc = async () => {
        const getData = location.state.value
        const id = { 'id': getData }
        const data = await axios.post('/api/v1/getspecificcatbyId', id)
        console.log(data)
        return data
    }
    useEffect(() => {
        myFunc()
            .then((res) => {
                const finaldata = res.data.rows[0]
                setCategory(finaldata)
                console.log(finaldata)

            })
    }, [location]);

    const imageUpload = (event) => {
        console.log(event.target.files[0])
        setCategory({ ...category, image: event.target.files[0] })

    }
    console.log(category)
    const handleChange = e => {
        let { name, value } = e.target
        setCategory({
            ...category,
            [name]: value
        })
    }

    const addCategory = async (e) => {
        e.preventDefault();
        console.log('category ', category)
        let url = '/api/v1/updatecategory';
        console.log('===', category.image, "===", category.image.name)
        const formdata = new FormData()
        console.log(formdata)
        formdata.append('myFile', category.image, category.image.name)
        console.log(formdata)
        formdata.append('id', category.id)
        formdata.append('name', category.name)
        formdata.append('code', category.code)
        formdata.append('description', category.description)
        try {

            let response = await axios.put(url, formdata)
            .then((res) => {
                toast.success('Update SuccessFully...', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
            .finally(setTimeout(function(){
                navigate('/category')
            },3000))
            .catch(() => {
                toast.error("sorry only admin can Update", {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })


        } catch (error) {
            console.error('Something went wrong')
        }
        return
    }
    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <div className="row">
                <div className="col-12">
                    <h1>Edit Category</h1>
                </div>
            </div>
            <form enctype="multipart/form-data">
                <div className="row">
                    <div className="col-lg-3">
                        {console.log("category", category)}
                        <label>
                            <h5>Name</h5>
                        </label>
                        {/* 1 */}
                        <input type="text" className="form-control form " placeholder="Enter Name" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="name" value={category.name} onChange={handleChange} />
                    </div>
                    <div className="col-lg-3">
                        <label>
                            <h5>Code</h5>
                        </label>
                        {/* 2 */}
                        <input type="text" className="form-control form " placeholder="Enter Code" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="code" value={category.code} onChange={handleChange} />
                    </div>

                    <div className="row">
                        <div className="col-lg-6 pe-0 mt-3">
                            <label>
                                <h5>Description</h5>
                            </label>
                            {/* 4 */}
                            <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Add Description" rows="5" name="description" value={category.description} onChange={handleChange} ></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-3">
                                <label className='form-label'>Upload Profile</label>
                                <input type="file" className='form-control' name="myFile" autoComplete='off' onChange={imageUpload}></input>
                            </div>
                        </div>
                        <div className="col-4 mt-3">
                            <button className="btn  btn-primary Add-1" type="button" onClick={addCategory}>Add</button>
                        </div>
                    </div>


                </div>
            </form>
            <ToastContainer />
        </div>
    )
}



export default EditCategory