import {
    ALL_SALEHISTORY_REQUEST,
    ALL_SALEHISTORY_SUCCESS,
    ALL_SALEHISTORY_FAIL,
    NEW_SALEHISTORY_REQUEST,
    NEW_SALEHISTORY_SUCCESS,
    NEW_SALEHISTORY_FAIL,
    NEW_SALEHISTORY_RESET,
    UPDATE_SALEHISTORY,

    CLEAR_ERRORS
} from '../Constrants/SaleHistoryConstrants'
export const saleHistoryReducer = (state = { saleHistory : [] }, action) => {
    switch (action.type) {
        case ALL_SALEHISTORY_REQUEST:
            return {
                loading: true,
                saleHistory: []
            }
        case ALL_SALEHISTORY_SUCCESS:
            return {
                loading: false,
                rows: action.payload.rows,
                // categoriesCount: action.payload.categoriesCount
            }
        // case UPDATE_QUANTITY:
        //     const { index, quantity } = action.payload;
        //     const prods = state.products.map((p, i) => {
        //         if (i !== index)
        //             return p;
        //         return {
        //             ...p,
        //             quantity
        //         }
        //     });
        //     return {
        //         loading: true,
        //         products: prods
        //     }

        case ALL_SALEHISTORY_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const newProductReducer = (state = { promotions: {} }, action) => {
    switch (action.type) {

        case NEW_SALEHISTORY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_SALEHISTORY_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                product: action.payload.product
            }

        case NEW_SALEHISTORY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_SALEHISTORY_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}