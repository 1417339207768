import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PacmanLoader from "react-spinners/PacmanLoader";
import ClockLoader from "react-spinners/ClockLoader";
const Logout = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate()
    const gotologin = () => {


        localStorage.removeItem("TOKEN")
        localStorage.removeItem("id")
        localStorage.removeItem("role")
        window.location.reload(false);
    }
    useEffect(() => {

        // Wait for 3 seconds
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    // Custom css for loader
    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;


    return (
        isLoading ?
        <div className="container">
            <div className="row">
                <div className="col d-flex justify-content-center mt-5">
                    <img src="/assets/images/thank-you-screen.jpg" alt="About" className='text-center img-fluid logout-img' />
                </div>
            </div>
        </div>

        :

        <h1 className="App">
            {gotologin()}

        </h1>
    )
}
export default Logout