import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { taxSchema } from "./taxSchema";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
const AddTax = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(taxSchema),
    });

    const navigate = useNavigate();
    const submitForm = async (data) => {
        console.log("data:", data);
        const url = `/api/v1/addtax`;
        await axios
            .post(url, data)
            .then((res) => {
                console.log(res);
                console.log(res.data.data.role);
                console.log("Success !!");

                navigate('/tax')
                //  notify();
            })
            .catch((error) => {
                console.log(error);
                // notifyError();
            });
    };

    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <form className="row bg-hite" onSubmit={handleSubmit(submitForm)}>
                <div className="col-12">
                    <h1>Add Tax</h1>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Tax Name</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Product Name"
                        className="form-control form"
                        {...register("name")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.name?.message} </p>
                </div>
                <div className="col-lg-3 ">
                    <label>
                        <h5>Type</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Category"
                        className="form-control form"
                        {...register("type")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.type?.message} </p>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Tax Value</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Buying Price"
                        className="form-control form"
                        {...register("taxValue")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.taxValue?.message} </p>
                </div>
                <div className="col-lg-3 ">
                    <label>
                        <h5>Description</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Description"
                        className="form-control form"
                        {...register("description")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.description?.message} </p>
                </div>






                <div className="col-4 mt-3">
                    <input
                        className="btn btn-primary Add-1"
                        type="submit"
                        value="Add"
                    />
                </div>
            </form>
            {/* <ToastContainer /> */}
        </div>




    );

};
export default AddTax;