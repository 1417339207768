import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { BiAnalyse, BiSearch } from "react-icons/bi";
const Search = ({searchHandler}) => {
  
    const [keyword, setKeyword] = useState('');
    console.log('my promotion key word',keyword)
    const onSearch = (e) => {
        e.preventDefault()
        searchHandler(keyword)

        // if (keyword.trim()) {
        //     navigate(`/search/${keyword}`)
        //     // history.push(`/search/${keyword}`)
        // } else {
        //     navigate('/')
        //     // history.push('/')
        // }
    }

    return (
        <form onSubmit={onSearch} >
            <div className="input-group">
                <input
                    type="text"
                    id="search_field"
                    className="form-control"
                    placeholder="Enter Bill Number ..."
                    onChange={(e) => setKeyword(e.target.value)}
                />
                <div className="input-group-append">
                    <button id="search_btn" className="btn btn-primary">
                        <i className="fa fa-search" aria-hidden="true"><BiSearch /></i>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default Search