import React, { useEffect, useReducer, useState } from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import { useDispatch, useSelector } from 'react-redux';
import Posts from './Posts';
import { getPromotions, updateQuantity } from '../../services/Action/promotionsAction'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import Search from './Search';
const Promotions = () => {
    const navigate = useNavigate();
    //for pagination
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState(5)
    const [promotionscopy, setPromotionscopy] = useState()
    const [name, setName] = useState(0);
    //for redux area to fetch data ON
    const dispatch = useDispatch();
    const { rows } = useSelector(state => state.promotions);
    console.log('My data is', rows)
const [reload,setRelod] =useState()

    useEffect(() => {
        setLoading(true);
        dispatch(getPromotions());
        setLoading(false);

    }, [dispatch,reload], name)
    //for redux area to fetch Off 


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = promotionscopy && promotionscopy.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = pageNumber => setCurrentPage(pageNumber);

    //go to add foam 
    const goToNextPage = () => {
        navigate('/addpromotions')
    }
    useEffect(() => {
        setPromotionscopy(rows)
    }, [rows])
    const searchHandler = (data) => {
        // data.preventDefault()
        console.log('****()****', data)
        const filterPromotions = rows.filter(obj => obj?.promotion_name?.includes(data))
        setPromotionscopy(filterPromotions)
    }
    const headers = [
        { label: "Promotions Name", key: "name" },
        { label: "Promotions Code ", key: "code" },
        { label: "Promotions Id", key: "PromotionsId" },
        { label: "Description", key: "description" }
    ];
    const csvLink = {
        filename: 'Promotions.csv',
        headers: headers,
        data: rows
    }
    return (
        <div className='container-fluid py-5 px-5 bg-white  full-height'>
            <div className="row ">
                <div className="col-lg-2">
                    <button className="btn btn-primary mb-4" onClick={goToNextPage}>Add Item</button>
                </div>

            </div>
            <div className="row">
                <div className="col-lg-5">
                    <h1>Promotions</h1>
                </div>
                <div className="col-lg-2">
                    {
                        rows &&
                        <CSVLink {...csvLink} className="btn btn-primary mx-2">CSV</CSVLink>
                    }
                </div>
                <div className="col-lg-5">
                    <Search searchHandler={searchHandler} />
                </div>
                <Posts posts={currentPosts} loading={loading} setName={setName} setRelod={setRelod}/>
                <Pagination postsPerPage={postsPerPage} totalPosts={rows && rows?.length} paginate={paginate} />

            </div>
            <ToastContainer />
        </div>
    )
}

export default Promotions