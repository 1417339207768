import './App.css';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './component/Login/Login';
import CashierSale from './component/CashierSale/CashierSale';
import { Provider, useSelector } from 'react-redux'
import store from '../src/services/Store/Store'
import Dashboard from './component/Dashboard/Dashboard';
import SideBar from './component/Siderbar/Sidebar';
// import AuthRoute from './component/Login/authRoute';
import MainLayout from './component/MainLayout/MainLayout';
import AuthRoute from './component/Login/AuthRoute';

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <AuthRoute/>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
