import * as yup from "yup";
export const taxSchema = yup.object().shape({
    name: yup.string().required('Tax Name is required'),
    type: yup.string().required('Tax Type is required'),
   
    taxValue: yup.string().required('Tax Value is required'),
    description: yup.string().required('Description is required'),
 // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  //   "Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character"
  // ),

});
