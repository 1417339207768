import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import AddItem from '../Items/AddItem';
import CashierSale from '../CashierSale/CashierSale';
import Dashboard from '../Dashboard/Dashboard';
import MainLayout from '../MainLayout/MainLayout';
import SaleHistory from '../SaleHistory/SaleHistory';
import Login from './Login';
import Items from '../Items/Items';
import Category from '../Category/Category';
import AddCategory from '../Category/AddCategory';
import EditCategory from '../Category/EditCategory';
import Product from '../Product/Product';
import AddProduct from '../Product/AddProduct';
import Testing from '../Testing/Testing';
import EditProduct from '../Product/EditProduct';
import Tax from '../Tax/Tax';
import AddTax from '../Tax/AddTax';
import EditTax from '../Tax/EditTax';
import Promotions from '../Promotions/Promotions';
import AddPromotions from '../Promotions/AddPromotions';
import EditPromotions from '../Promotions/EditPromotions';
import Users from '../Users/Users';
import AddUsers from '../Users/AddUsers';
import EditUsers from '../Users/EditUsers';
import Printpage from '../CashierSale/Printpage';
import Logout from '../Logout/Logout';

const AuthRoute = () => {
  const { isAuthenticated } = useSelector(state => state.auth);

  const CheckAuthetication = ({ children }) => {
    // alert(authenticated);
    // if(isAuthenticated){
    if (localStorage.getItem('TOKEN') ) {
      
      return children

    } else {
      return <Navigate to='login' replace />
    }
  }
  return (
    <>
      <Routes>
        <Route exact path='login' element={<Login />} />
        <Route path='/' element={
          <CheckAuthetication>
            <MainLayout />
          </CheckAuthetication>
        }>
          <Route index element={<Dashboard />} />
          
          <Route path='cashiersale' element={<CashierSale />} />
          <Route path='salehistory' element={<SaleHistory />} />


          <Route path='category' element={<Category />} />
          <Route path='addcategory' element={<AddCategory />} />
          <Route path='editcategory' element={<EditCategory />} />
          <Route path='product' element={<Product />} />
          <Route path='addproduct' element={<AddProduct />} />
          <Route path='editproduct' element={<EditProduct />} />
          <Route path='testing' element={<Testing />} />
          {/*     tax routes */}
          <Route path='tax' element={<Tax />} />
          <Route path='addtax' element={<AddTax />} />
          <Route path='edittax' element={<EditTax />} />
          {/* promotions routes */}
          <Route path='promotions' element={<Promotions />} />
          <Route path='addpromotions' element={<AddPromotions />} />
          <Route path='editpromotions' element={<EditPromotions />} />
          {/* users routes */}
          <Route path='users' element={<Users />} />
          <Route path='addusers' element={<AddUsers />} />
          <Route path='editusers' element={<EditUsers />} />
          <Route path='printpage' element={<Printpage />} />
          {/* logout */}
          <Route path='logout' element={<Logout />} />
        </Route>
        <Route path='*' element={<div><p>ERROR PAGE</p></div>} />
      </Routes>
    </>
  )
}

export default AuthRoute