import axios from 'axios'
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Posts = ({ posts, loading,sorting }) => {
    console.log('my posts are available',posts)
    
    const navigate = useNavigate();
    if (loading) {
        return <h2>loading....</h2>
    }
    //delete function 
    const deleteTax = async (id) => {
        try {
            const res = await axios.delete(`/api/v1/deletetax/${id}`)
                .then(() => {
                    console.log('product delete')
                    toast.error("Product has been Deleted", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(() => {
                    toast.error("sorry only admin can insert", {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                });
        } catch (error) {
            console.log(error)
        }}
    const goToEditPage = (value) => {
        navigate('/edittax', { state: { value } })
        // navigate('/editcategory')
    }
    return (
        <div className="col-lg-12">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" onClick={()=>sorting("name")}>Tax Name</th>
                        <th scope="col">Tax Type</th>
                        <th scope="col" onClick={()=>sorting("buyingPrice")}>Tax Value</th>
                        <th scope="col">Description</th>
                       
                       
                        <th scope="col">Edit</th>
                        {/* <th scope="col">Delete</th> */}
                    </tr>
                </thead>
                <tbody >
                 
                    {
                        posts && posts.map((item) => (
                            <tr key={item.id} className="p-5">
                                <td scope="row">{item.taxName}</td>
                                <td>{item.type}</td>
                                <td>{item.taxValue}</td>
                                <td>{item.description}</td>
                             
                                
                                <td><button className="btn btn-primary" value={item.id}
                                    onClick={(e) => { goToEditPage(e.target.value) }}
                                >Edit</button></td>
                                {/* <td><button className="btn btn-danger" value={item.id}
                                    onClick={(e) => { deleteTax(e.target.value) }}>Delete</button></td> */}

                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Posts