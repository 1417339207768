import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    console.log('my post',postsPerPage)
    console.log('total post',totalPosts)
    console.log('my paginate',paginate)
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
   
    <div className="col-lg-12">
    <nav className='d-flex justify-content-center'>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className='page-item'>
            <a onClick={() => paginate(number)} className='page-link'>
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
    </div>
  );
};

export default Pagination;