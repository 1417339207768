//Here i am calling DATA
import React, { useEffect, useState } from 'react'
import axios from 'axios';
const ItemList = (props) => {
    const [fdata, setFdata] = useState(0);
    const value = props.myData
    const myFunc = async () => {
        const getData = props.myData
        const categoryId_fk = { 'categoryId_fk': getData }
        // console.log('category id', categoryId_fk)
        const data = await axios.post('/api/v1/getspecificcat/', categoryId_fk)
        return data
    }
    useEffect(() => {
        myFunc()
            .then((res) => {
                const finaldata = res.data
                setFdata(finaldata)
            })
    }, [value]);
    const data = fdata
    return (
        <React.Fragment>
            <div className="container py-4 mb-md-5">
                <div className="row table-responsive">
                    <div className="col-12"><h1>Items</h1></div>
                    <div className="col-7">
                        <label className='me-2'>Items</label>
                        <input type="text" className="text-box" placeholder="Username" />
                        <button className='btn btn-primary ms-2 mb-1' >Search</button>
                    </div>
                    {/* <div className="col-5">
                        <label className='me-2'>Items</label>
                        <input type="text" className="text-box" placeholder="Username" />
                    </div> */}
                    <div className="col-12 d-flex">
                        {
                            data && data.map((item) => (
                                <div onClick={() => props.setItemtorder(item.id)}>
                                    <div className="card text-center m-2 py-3 " style={{ width: 10 + 'rem' }}>

                                        <img className='m-auto mt-2' src={item.image} alt="Avatar" style={{ width: 100 + 'px' }} />
                                        <label>{item.name}</label>
                                    </div>
                                </div>
                            )

                            )
                        }
                    </div>

{/* 
                    <div className='wrapper1 '>
                        {
                            data && data.map((item, index) => (
                                <div onClick={() => params.setName(item.id)} >
                                    <div className="card text-center m-2 py-3 " style={{ width: 10 + 'rem' }}>
                                        <img className='m-auto ' src={`${process.env.REACT_APP_BASEURL}${item.image}`} alt="Avatar" style={{ width: 100 + 'px' }} />
                                        <label>{item.name}</label>
                                    </div>
                                </div>
                            ))
                        }
                    </div> */}


                </div>
            </div>



        </React.Fragment>
    )
}

export default ItemList