//product Reducer
import {
    GET_PRODUCTS_BY_CATEGORY_REQUEST,
    GET_PRODUCTS_BY_CATEGORY_SUCCESS,
    GET_PRODUCTS_BY_CATEGORY_SUCCESS_FAIL,
    ALL_PRODUCT_REQUEST,
    ALL_PRODUCT_SUCCESS,
    ALL_PRODUCT_FAIL,

    CLEAR_ERRORS
} from '../Constrants/ProductConstrants.js'
// export const productReducer = (state = { products : [] }, action) => {
//     switch (action.type) {
//         case ALL_PRODUCT_REQUEST:
//             return {
//                 loading: true,
//                 categories: []
//             }
//         case ALL_PRODUCT_SUCCESS:
//             return {
//                 loading: false,
//                 rows: action.payload.rows,
             
//             }
//         case ALL_PRODUCT_FAIL:
//             return {
//                 loading: false,
//                 error: action.payload
//             }
//         case CLEAR_ERRORS:
//             return {
//                 ...state,
//                 error: null
//             }
//         default:
//             return state
//     }
// }
export const productReducer  = (state = { products : []},action)=>{
    switch(action.type){
        case ALL_PRODUCT_REQUEST:
            return {
                loading: true,
                categories: []
            }
        case ALL_PRODUCT_SUCCESS:
            return {
                loading: false,
                rows: action.payload.rows,
             
            }
        case ALL_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case GET_PRODUCTS_BY_CATEGORY_REQUEST:
            return {
                loading: true,
                products: []
            }
        case GET_PRODUCTS_BY_CATEGORY_SUCCESS:
            return{
                loading: false,
                rows: action.payload.rows,
            }
            case GET_PRODUCTS_BY_CATEGORY_SUCCESS_FAIL:
                return {
                    loading: false,
                    error: action.payload
                }
                case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
            default:
            return state
    }
}