import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { usersSchema } from "./usersSchema";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const AddUsers = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(usersSchema),
    });

    const navigate = useNavigate();
    const submitForm = async (data) => {
        console.log("here data:", data);
        const url = `/api/v1/registerUser`;
        await axios
            .post(url, data)
            .then(() => {
                console.log('it is in toast domain')
                toast.success('Added SuccessFully...', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            )
            // .finally(setTimeout(function () {
            //     navigate('/users')
            // }, 3000))
    };

    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <form className="row bg-hite" onSubmit={handleSubmit(submitForm)}>
                <div className="col-12">
                    <h1>Add Users</h1>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Users Name</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter User Name"
                        className="form-control form"
                        {...register("name")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.name?.message} </p>
                </div>
                <div className="col-lg-3 ">
                    <label>
                        <h5>Role</h5>
                    </label>
                    <select className="form-select form" aria-label="Default select example"  {...register("role")}>
                        <option selected>Select User Role</option>

                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                   

                    </select>
                    <p className="text-sm text-danger ml-3"> {errors.role?.message} </p>
                    {/* <input
                        type="text"
                        placeholder="Enter Category"
                        className="form-control form"
                        {...register("categoryId_fk")}
                    /> */}
                  
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Email</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter User Email"
                        className="form-control form"
                        {...register("email")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.email?.message} </p>
                </div>
                <div className="col-lg-3">
                    <label>
                        <h5>Password</h5>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter User Password"
                        className="form-control form"
                        {...register("password")}
                    />
                    <p className="text-sm text-danger ml-3"> {errors.password?.message} </p>
                </div>
       






                <div className="row">
                    <div className="col-4 mt-3">
                        <input
                            className="btn btn-primary Add-1"
                            type="submit"
                            value="Add"
                        />
                    </div>
                </div>


            </form>
            <ToastContainer />
        </div>




    );

};
export default AddUsers;