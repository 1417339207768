import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";


const Dashboard = () => {
  const [promotions, setPromotions] = useState()
  const [weekly, setWeekly] = useState()
console.log('promotions',promotions)

  const sumofsale = promotions && promotions?.rows?.map((item, key) => item?.TotalSale)
  const sumofsaleperweek = weekly && weekly?.rows?.map((item, key) => item?.TotalSale)

  const sumofFinaldata = sumofsale?.reduce(mysumFunc);
  const sumofFinalweekdata = sumofsaleperweek?.reduce(mysumweekFunc);
  // const sumofFinaldata = sumofsale?.reduce((mysumFunc)=> mysumFunc,0);
  // const sumofFinalweekdata = sumofsaleperweek?.reduce((mysumweekFunc)=>mysumweekFunc,0);


  function mysumFunc(total, num) {
    return total + num;
  }
  function mysumweekFunc(total, num) {
    return total + num;
  }

  const { user } = useSelector(state => state.auth);
  const myvar = localStorage.getItem("id")


  const myFunc = async () => {
    // const getData = location.state.value
    const id = { 'id': myvar }
    const data = await axios.post('/api/v1/getdashboardbyId', id)
console.log('ggggg',data)
    return data
  }
  const myFuncweek = async () => {
    // const getData = location.state.value

    const id = { 'id': myvar }
    const data = await axios.post('/api/v1/getdashboardbyIdweekly', id)

    return data
  }
  useEffect(() => {
    myFunc()
      .then((res) => {
        const finaldata = res.data

        setPromotions(finaldata)

      })
  }, user.id);
  useEffect(() => {
    myFuncweek()
      .then((res) => {
        const finaldata = res.data

        setWeekly(finaldata)

      })
  }, user.id);
  return (
    <div className="container-fluid dashboard-card-bg">
      <div className="row">
        <div className="col-12">
          <h1 className="mt-5 fw-bold ms-5 ps-4">Dashboard</h1>
        </div>

      </div>
      <div className="row d-flex justify-content-center  ">
        <div className="col-11 px-0 mx-0 ">
          <hr className="" />
        </div>
      </div>


      <div className="row d-flex justify-content-center mt-5 mx-5 bg-white py-4 ">
        <div className="col-12">
          <h3 className="mb-4">Sales Statistics</h3>
          <hr />
        </div>
        <div className="col-12 d-flex justify-content-center">
          <h3 className="mb-2 ">Daily Sales <hr className="" /></h3>
        </div>

        {

          promotions && promotions?.rows?.map((item) => (
            <div className="col-lg-2 col-md-3 col-sm-6 mt-sm-3 mt-3 mt-md-0 mt-lg-0 col-6 align-self-center">
              <div className="card text-dark dashboard-card-bg  text-center  " >

                <div className="card-body">

                  <h2 className="card-title fw-bold">{item?.TotalSale}</h2>

                  <p className="fs-6">{item?.TDate}</p>
                </div>
              </div>
            </div>
          ))
        }


        <div className="col-lg-3 col-md-12 col-sm-12  col-12 mt-md-3 mt-sm-2 mt-lg-0 mt-2">
          <div className="card text-white bg-primary  text-center " >

            <div className="card-body my-3">
              <h5 className="card-title">{sumofFinaldata}<span className="ms-2">Rs</span></h5>
              <p className="card-text">WEEKLY TOTAL SALE AMOUNT.</p>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <h3 className="my-3 ">Weekly Sales  <hr className="" /></h3>
        </div>

        {

          weekly && weekly?.rows?.map((item) => (
            <div className="col-lg-2 col-md-3 col-sm-6 mt-sm-3 mt-3 mt-md-0 mt-lg-0 col-6 align-self-center">
              <div className="card text-dark dashboard-card-bg  text-center  " >

                <div className="card-body">

                  <h2 className="card-title fw-bold">{item?.TotalSale}</h2>

                  <p className="fs-6">{item?.TDate}</p>
                </div>
              </div>
            </div>
          ))
        }


        <div className="col-lg-3 col-md-12 col-sm-12  col-12 mt-md-3 mt-sm-2 mt-lg-0 mt-2">
          <div className="card text-white bg-primary  text-center " >

            <div className="card-body my-3">
              <h5 className="card-title">{sumofFinalweekdata}<span className="ms-2">Rs</span></h5>
              <p className="card-text">WEEKLY TOTAL SALE AMOUNT.</p>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Dashboard