import axios from 'axios';
import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_REQUEST_BY_ID,
    ALL_CATEGORY_SUCCESS,
    NEW_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
    NEW_CATEGORY_FAIL,
    UPDATE_CATEGORY,
    ALL_CATEGORY_SUCCESS_BY_ID,
 
    NEW_CATEGORY_REQUEST,
    CLEAR_ERRORS
} from '../Constrants/CategoryConstrants.js'

export const getCategory = () =>async (dispatch)=>{
    try {
        dispatch({type: ALL_CATEGORY_REQUEST})
        const{ data } = await axios.get('/api/v1/getcategory')
        console.log(typeof(data))
        dispatch({
            type: ALL_CATEGORY_SUCCESS,
            payload: data
        })
         
    } catch (error) {
        dispatch({
            type:ALL_CATEGORY_FAIL,
            payload: error.response.data.message
        })
        
    }
}
export const clearErrors = () => async (dispatch)=>{
    dispatch({
        type:CLEAR_ERRORS
    })
}

export const newCategory = (categoryData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_CATEGORY_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/addcategory`, categoryData, config)

        dispatch({
            type: NEW_CATEGORY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_CATEGORY_FAIL,
            payload: error.response.data.message
        })
    }
}
// export const getProductsbyFind = (myvariable) =>async (dispatch)=>{
//     try {
//         console.log(myvariable)
//         dispatch({type: ALL_PRODUCTS_REQUEST_BY_ID})
//         const{ data } = await axios.get(`/api/v1/product/${myvariable}`)
//         console.log(data)
//         dispatch({
//             type: ALL_PRODUCTS_SUCCESS_BY_ID,
//             payload: data
//         })
        
//     } catch (error) {
//         dispatch({
//             type:ALL_PRODUCTS_FAIL,
//             payload: error.response.data.message
//         })
        
//     }
// }
// export const updateQuantity = (index, quantity) => (dispatch, state) => {
//         const data = state.products;
 
//     dispatch({
//         type: UPDATE_QUANTITY,
//         payload: {index, quantity}
//     })
// }
// //clear errors
// export const clearErrors = () => async(dispatch)=>{
//     dispatch({
//         type:CLEAR_ERRORS
//     })
// }