import axios from 'axios'
import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const EditUsers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [users, setUsers] = useState({
        id: location.state.value,
        name: "",
        role: "",
        email:"",
        password:"",
    })

    console.log('****', location.state.value)
    // it is fecthing data by id 
    const myFunc = async () => {
        const getData = location.state.value
        const id = { 'id': getData }
        console.log('hey i am running')
        const data = await axios.post('/api/v1/getspecificusers', id)
        console.log('stopped *** ', data)
        return data
    }
    useEffect(() => {
        myFunc()
            .then((res) => {
                const finaldata = res.data.rows[0]

                setUsers(finaldata)

            })
    }, [location]);



    const handleChange = e => {
        let { name, value } = e.target
        setUsers({
            ...users,
            [name]: value
        })
    }

    const EditUsers = async (e) => {
        e.preventDefault();

        let url = '/api/v1/updateusers';

        try {

            let response = await axios.put(url, users)
                .then(() => {
                    console.log('it is in toast domain')
                    toast.success('Edit SuccessFully...', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .finally(setTimeout(function () {
                    navigate('/users')
                }, 3000))

            if (response.status == 200) {
                console.success('Added Successfully...')

            }

        } catch (error) {
            console.error('Something went wrong')
        }
        return
    }
    return (
        <div className="container-fluid px-5 bg-white full-height pt-5">
            <div className="row">
                <div className="col-12">
                    <h1>Edit Users</h1>
                </div>
            </div>
            <form enctype="multipart/form-data">
                <div className="row">
                    <div className="col-lg-3">
                        {console.log("category", users)}
                        <label>
                            <h5>Name</h5>
                        </label>
                        {/* 1 */}
                        <input type="text" className="form-control form " placeholder="Enter Name" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="name" value={users.name} onChange={handleChange} />
                    </div>
                    <div className="col-lg-3">
                        {console.log("category", users)}
                        <label>
                            <h5>Role</h5>
                        </label>
                        {/* 1 */}
                        <input type="text" className="form-control form " placeholder="Enter Name" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="role" value={users.role} onChange={handleChange} />
                    </div>
                    <div className="col-lg-3">
                        {console.log("category", users)}
                        <label>
                            <h5>Email</h5>
                        </label>
                        {/* 1 */}
                        <input type="text" className="form-control form " placeholder="Enter Name" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="email" value={users.email} onChange={handleChange} />
                    </div>
                    <div className="col-lg-3">
                        {console.log("category", users)}
                        <label>
                            <h5>Password</h5>
                        </label>
                        {/* 1 */}
                        <input type="text" className="form-control form " placeholder="Enter Name" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" name="password" value={users.password} onChange={handleChange} />
                    </div>



             

                    <div className="row">

                        <div className="col-4 mt-3">
                            <button className="btn  btn-primary Add-1" type="button" onClick={EditUsers}>Update</button>
                        </div>
                    </div>


                </div>
            </form>
            <ToastContainer />
        </div>
    )
}



export default EditUsers