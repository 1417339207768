import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'


import { authReducer,usersReducer } from '../Reducers/userReducer.js';
import { categoryReducer } from '../Reducers/categoryReducer.js'
import { productReducer } from '../Reducers/productReducer.js'
import { promotionsReducer } from '../Reducers/promotionsReducer'
import { saleHistoryReducer } from '../Reducers/salehistoryReducer'
import { taxReducer } from '../Reducers/taxReducer'
const reducer = combineReducers({
    saleHistory: saleHistoryReducer,
    promotions: promotionsReducer,
    tax: taxReducer,
    product: productReducer,
    category: categoryReducer,
    auth: authReducer,
    users:usersReducer

})

let initialState = {}

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store