//product Action
import axios from 'axios';
import {
    GET_PRODUCTS_BY_CATEGORY_REQUEST,
    GET_PRODUCTS_BY_CATEGORY_SUCCESS,
    GET_PRODUCTS_BY_CATEGORY_SUCCESS_FAIL,
    ALL_PRODUCT_REQUEST,
    ALL_PRODUCT_SUCCESS,
    ALL_PRODUCT_FAIL,


    CLEAR_ERRORS
} from '../Constrants/ProductConstrants.js'

export const getProduct= () =>async (dispatch)=>{
    try {
        dispatch({type: ALL_PRODUCT_REQUEST})
        const{ data } = await axios.get('/api/v1/products')

        dispatch({
            type: ALL_PRODUCT_SUCCESS,
            payload: data
        })
         
    } catch (error) {
        dispatch({
            type:ALL_PRODUCT_FAIL,
            payload: error.response.data.message
        })
        
    }
}
export const getProductByCategory = (categoryId_fk) => async(dispatch)=>{
    console.log(categoryId_fk)
    try {
        dispatch({type: GET_PRODUCTS_BY_CATEGORY_REQUEST})
        const{ data } = await axios.get(`/api/v1/catproduct/${categoryId_fk}`)
        console.log(data)
        dispatch({
            type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type:GET_PRODUCTS_BY_CATEGORY_SUCCESS_FAIL,
            payload: error.response.data.message
        })
    }
}