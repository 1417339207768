import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const Printpage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('****', location.state.data)
    const data = [location.state.data]
    console.log('My data is ', data[0].cashierName)
    const print = () => {
        const btn = document.getElementById('myBtn');
        btn.style.visibility = 'hidden';

        window.print()
    }


    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    return (
        <div>
        <div className="row d-flex justify-content-center">
            <div className="col-6  align-self-center">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title text-center">Thank for your purchase</h5>
                    <h6 class="card-subtitle mb-2 text-muted"><span>Cashier: </span>{data[0].cashierName}</h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span>Date: </span>{dateTime}</h6>
                    <hr/>
                    <h6 class="card-subtitle mb-2 text-muted"><span> Total Number Of items</span> <span className='d-flex justify-content-end'>{data[0].TotalItems}</span></h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span> Sub Amount</span> <span className='d-flex justify-content-end'>{data[0].SubTotal}</span></h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span> Witholding</span> <span className='d-flex justify-content-end'>{data[0].Witholding}</span></h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span> GST</span> <span className='d-flex justify-content-end'>{data[0].Gst}</span></h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span> Total Tax Amount</span> <span className='d-flex justify-content-end'>{data[0].TotalTax}</span></h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span> After Tax Amount</span> <span className='d-flex justify-content-end'>{data[0].AmountAfterTax}</span></h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span> Discount</span> <span className='d-flex justify-content-end'>{data[0].Discount}</span></h6>
                    <h6 class="card-subtitle mb-2 text-muted"><span> Discounted Amount</span> <span className='d-flex justify-content-end'>{data[0].DiscountAmount}</span></h6>
                    <hr/>
                    <h6 class="card-subtitle mb-2 text-muted"><span> Total Amount</span> <span className='d-flex justify-content-end'>{data[0].TotalPayment}</span></h6>
                    <hr/>
                    {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <a href="#" class="card-link">Card link</a>
                    <a href="#" class="card-link">Another link</a> */}
                </div>
            </div>
            </div>
            <div className="col-12 text-center mt-5 ">
            <button id='myBtn' className='btn btn-primary ' onClick={() => print()}>Print</button>
            </div>
        </div>

         
          
        </div>
    )
}

export default Printpage